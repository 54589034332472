<template>
  <component-frame title="Cards">
    <div class="sg-page__heading">
      <div class="on-this-page">
        <div class="on-this-page-title">On This Page</div>
        <ul class="on-this-page-anchor-list">
          <li>
            <a href="#" v-scroll-to="'#selectable-single'"
              >Selectable Cards - Single Select</a
            >
          </li>
          <li>
            <a href="#" v-scroll-to="'#selectable-multi'"
              >Selectable Cards - Multi Select</a
            >
          </li>
        </ul>
      </div>
    </div>

    <h3 id="selectable-single">Selectable cards - single select</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <label class="card-selectable" for="selectableCardSingle1">
            <input
              type="radio"
              name="selectableCardSingle"
              class="d-none"
              id="selectableCardSingle1"
            />
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">JaneSmith-LS.pfx</h4>
                <ul class="list-unstyled mb-0">
                  <li><strong>ID:</strong> X3TWPW</li>
                  <li><strong>Expires:</strong> Jun 10, 2018</li>
                </ul>
              </div>
            </div>
          </label>
          <label class="card-selectable" for="selectableCardSingle2">
            <input
              type="radio"
              name="selectableCardSingle"
              class="d-none"
              id="selectableCardSingle2"
            />
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">JohnSmith-LS.pfx</h4>
                <ul class="list-unstyled mb-0">
                  <li><strong>ID:</strong> X3TFHS</li>
                  <li><strong>Expires:</strong> Jun 10, 2022</li>
                </ul>
              </div>
            </div>
          </label>
        </div>
        <div class="sg-component__code">
          <div
            class="sg-component__toggle js-toggle-code"
            data-text-swap="Hide Code"
            v-toggleCode
          >
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;label class="card-selectable" for="selectableCardSingle1"&gt;
  &lt;input type="radio" name="selectableCardSingle" class="d-none" id="selectableCardSingle1"&gt;
  &lt;div class="card"&gt;
    &lt;div class="card-body"&gt;
      &lt;h4 class="card-title"&gt;JaneSmith-LS.pfx&lt;/h4&gt;
      &lt;ul class="list-unstyled mb-0"&gt;
        &lt;li&gt;&lt;strong&gt;ID:&lt;/strong&gt; X3TWPW&lt;/li&gt;
        &lt;li&gt;&lt;strong&gt;Expires:&lt;/strong&gt; Jun 10, 2018&lt;/li&gt;
      &lt;/ul&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/label&gt;
&lt;label class="card-selectable" for="selectableCardSingle2"&gt;
  &lt;input type="radio" name="selectableCardSingle" class="d-none" id="selectableCardSingle2"&gt;
  &lt;div class="card"&gt;
    &lt;div class="card-body"&gt;
      &lt;h4 class="card-title"&gt;JohnSmith-LS.pfx&lt;/h4&gt;
      &lt;ul class="list-unstyled mb-0"&gt;
        &lt;li&gt;&lt;strong&gt;ID:&lt;/strong&gt; X3TFHS&lt;/li&gt;
        &lt;li&gt;&lt;strong&gt;Expires:&lt;/strong&gt; Jun 10, 2022&lt;/li&gt;
      &lt;/ul&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/label&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>
            Selectable cards are substitutes for radio buttons and checkboxes
            where options contain detailed information
          </li>
          <li>For single select radio inputs should be used</li>
        </ul>
      </div>
    </div>

    <h3 id="selectable-multi">Selectable cards - multi select</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <label class="card-selectable" for="selectableCardMulti1">
            <input
              type="checkbox"
              name="selectableCardMulti1"
              class="d-none"
              id="selectableCardMulti1"
            />
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">JaneSmith-LS.pfx</h4>
                <ul class="list-unstyled mb-0">
                  <li><strong>ID:</strong> X3TWPW</li>
                  <li><strong>Expires:</strong> Jun 10, 2018</li>
                </ul>
              </div>
            </div>
          </label>
          <label class="card-selectable" for="selectableCardMulti2">
            <input
              type="checkbox"
              name="selectableCardMulti2"
              class="d-none"
              id="selectableCardMulti2"
            />
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">JohnSmith-LS.pfx</h4>
                <ul class="list-unstyled mb-0">
                  <li><strong>ID:</strong> X3TFHS</li>
                  <li><strong>Expires:</strong> Jun 10, 2022</li>
                </ul>
              </div>
            </div>
          </label>
          <label class="card-selectable" for="selectableCardMulti3">
            <input
              type="checkbox"
              name="selectableCardMulti3"
              class="d-none"
              id="selectableCardMulti3"
            />
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">JaneSmith-LS.pfx</h4>
                <ul class="list-unstyled mb-0">
                  <li><strong>ID:</strong> X3TWPW</li>
                  <li><strong>Expires:</strong> Jun 10, 2018</li>
                </ul>
              </div>
            </div>
          </label>
        </div>
        <div class="sg-component__code">
          <div
            class="sg-component__toggle js-toggle-code"
            data-text-swap="Hide Code"
            v-toggleCode
          >
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;label class="card-selectable" for="selectableCardMulti1"&gt;
  &lt;input type="checkbox" name="selectableCardMulti1" class="d-none" id="selectableCardMulti1"&gt;
  &lt;div class="card"&gt;
    &lt;div class="card-body"&gt;
      &lt;h4 class="card-title"&gt;JaneSmith-LS.pfx&lt;/h4&gt;
      &lt;ul class="list-unstyled mb-0"&gt;
        &lt;li&gt;&lt;strong&gt;ID:&lt;/strong&gt; X3TWPW&lt;/li&gt;
        &lt;li&gt;&lt;strong&gt;Expires:&lt;/strong&gt; Jun 10, 2018&lt;/li&gt;
      &lt;/ul&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/label&gt;
&lt;label class="card-selectable" for="selectableCardMulti2"&gt;
  &lt;input type="checkbox" name="selectableCardMulti2" class="d-none" id="selectableCardMulti2"&gt;
  &lt;div class="card"&gt;
    &lt;div class="card-body"&gt;
      &lt;h4 class="card-title"&gt;JohnSmith-LS.pfx&lt;/h4&gt;
      &lt;ul class="list-unstyled mb-0"&gt;
        &lt;li&gt;&lt;strong&gt;ID:&lt;/strong&gt; X3TFHS&lt;/li&gt;
        &lt;li&gt;&lt;strong&gt;Expires:&lt;/strong&gt; Jun 10, 2022&lt;/li&gt;
      &lt;/ul&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/label&gt;
&lt;label class="card-selectable" for="selectableCardMulti3"&gt;
  &lt;input type="checkbox" name="selectableCardMulti3" class="d-none" id="selectableCardMulti3"&gt;
  &lt;div class="card"&gt;
    &lt;div class="card-body"&gt;
      &lt;h4 class="card-title"&gt;JaneSmith-LS.pfx&lt;/h4&gt;
      &lt;ul class="list-unstyled mb-0"&gt;
        &lt;li&gt;&lt;strong&gt;ID:&lt;/strong&gt; X3TWPW&lt;/li&gt;
        &lt;li&gt;&lt;strong&gt;Expires:&lt;/strong&gt; Jun 10, 2018&lt;/li&gt;
      &lt;/ul&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/label&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>Use checkboxes when multiple options can be chosen</li>
        </ul>
      </div>
    </div>
  </component-frame>
</template>

<script>
export default {
  name: "cards",
  data() {
    return {
      title: "Cards"
    };
  }
};
</script>
